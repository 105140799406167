//画k线
export function drawK(options) {
  // console.log(options);
  var margin = { top: 10, bottom: 10, left: 0, right: 0 };
  var dataset = options.data;
  var width = options.width;
  var widthRatio = 1;
  var height = width * options.ratio;
  var ave5 = options.ave5;
  var ave10 = options.ave10;
  var ave20 = options.ave20;

  if (options.margin !== undefined) {
    margin.top = options.margin.top;
    margin.bottom = options.margin.bottom;
    margin.left = options.margin.left;
    margin.right = options.margin.right;
  }

  var svg = d3
    .select("#" + options.id)
    .html("")
    .append("svg")
    .attr("width", width)
    .attr("height", height);

  width = width - margin.left - margin.right;
  height = height - margin.top - margin.bottom;

  var yMin = d3.min(dataset, function(d) {
    return d.fLow;
  });
  var yMax = d3.max(dataset, function(d) {
    return d.fHigh;
  });
  // console.log(yMin,yMax);
  var yScale = d3.scale
    .linear()
    .domain([yMax, yMin])
    .range([margin.top, margin.top + height]);

//   //三条竖线
//   for (var i = 0; i < 3; i++) {
//     svg.append("line").attr({
//       x1: (width / 4) * (i + 1),
//       x2: (width / 4) * (i + 1),
//       y1: 0,
//       y2: height + margin.top + margin.bottom,
//       class: "grid_line",
//     });
//   }

  //y轴刻度
  var yAxis = d3.svg
    .axis()
    .scale(yScale)
    .orient("right")
    .ticks(5)
    .tickSize(-width)
    .tickPadding(5)
    .tickFormat(function(d) {
      return d.toFixed(2);
    });
  var yAxisSvg = svg
    .append("g")
    .attr("class", "axis axis_y")
    .attr("transform", "translate(" + 0 + ",0)")
    .call(yAxis);
  yAxisSvg.selectAll("text").attr("class", "size_20");
  // .attr("transform", "translate(" + 39 + ",0)")
  yAxisSvg
    .selectAll("line")
    .attr("class", "grid_line")
    .attr("transform", "translate(" + width + ",0)");

  //x轴序数比例尺
  var xScale = d3.scale
    .ordinal()
    .domain(
      dataset.map(function(d) {
        return d.lYmd;
      })
    )
    .rangeBands([0, width], 0.3);

  //画操作步骤背景涨跌幅
  var stepsArr = [];
  var tempStart, tempEnd;
  for (var i = 0; i < dataset.length; i++) {
    if (dataset[i].step == 1) {
      tempStart = i;
    } else if (dataset[i].step == 2) {
      tempEnd = i;
      stepsArr.push({
        start: tempStart,
        end: tempEnd,
      });
      tempStart = 0;
      tempEnd = 0;
    }
  }
  // console.log(stepsArr);

  if (options.bsMode === undefined || options.bsMode === false) {
    for (var i = 0; i < stepsArr.length; i++) {
      svg.append("rect").attr({
        x: function(d) {
          return xScale(dataset[stepsArr[i].start].lYmd) + xScale.rangeBand() / 2;
        },
        y: 0,
        width: function(d) {
          return xScale(dataset[stepsArr[i].end].lYmd) - xScale(dataset[stepsArr[i].start].lYmd);
        },
        height: height + margin.top + margin.bottom,
        class: function(d) {
          // return 'step_negative';
          if (dataset[stepsArr[i].end].fClose > dataset[stepsArr[i].start].fClose) {
            return "step_positive";
          } else {
            return "step_negative";
          }
        },
      });
    }
  }

  //k线的竖线
  var lines = svg
    .append("g")
    .attr("class", "k_lines_group")
    .selectAll("line")
    .data(dataset)
    .enter()
    .append("line")
    .attr({
      x1: function(d, i) {
        return xScale(d.lYmd) + xScale.rangeBand() / 2;
      },
      x2: function(d, i) {
        return xScale(d.lYmd) + xScale.rangeBand() / 2;
      },
      y1: function(d, i) {
        return yScale(d.fOpen);
      },
      y2: function(d, i) {
        return yScale(d.fOpen);
      },
      class: function(d) {
        if (d.fOpen <= d.fClose) return "kline_positive";
        else return "kline_negative";
      },
    });

  lines
    .transition()
    .delay(function(d, i) {
      return i * 60;
    })
    .duration(60)
    .attr("y1", function(d) {
      return yScale(d.fLow);
    })
    .attr("y2", function(d) {
      return yScale(d.fHigh);
    });

  //k线的柱子
  var rects = svg
    .append("g")
    .attr("class", "k_bars_group")
    .selectAll("rect")
    .data(dataset)
    .enter()
    .append("rect")
    .attr({
      x: function(d, i) {
        return xScale(d.lYmd);
      },
      y: function(d, i) {
        return d.fOpen < d.fClose ? yScale(d3.min([d.fClose, d.fHigh])) : yScale(d3.max([d.fClose, d.fLow]));
      },
      width: function(d, i) {
        return xScale.rangeBand();
      },
      height: function(d, i) {
        return 0;
      },
      class: function(d) {
        if (d.fOpen <= d.fClose) {
          return "kbar_positive";
        } else {
          return "kbar_negative";
        }
      },
    });

  rects
    .transition()
    .delay(function(d, i) {
      return i * 60;
    })
    .duration(60)
    .attr("y", function(d) {
      return yScale(d3.max([d.fOpen, d.fClose]));
    })
    .attr("height", function(d) {
      var rectHeight = Math.abs(yScale(d.fOpen) - yScale(d.fClose));
      return rectHeight;
    });

  //===============开始画均线===============================
  const duration = dataset.length * 60;

  //5日均线
  var line5 = d3.svg
    .line()
    .x(function(d) {
      return xScale(d.lYmd) + xScale.rangeBand() / 2;
    })
    .y(function(d) {
      return yScale(d.ave5);
    });
  var ave5 = svg
    .append("path")
    .attr("class", "line5")
    .attr("d", line5(dataset));
  const ave5TotalLength = ave5.node().getTotalLength();
  ave5
    .attr("stroke-dasharray", ave5TotalLength + "," + ave5TotalLength)
    .attr("stroke-dashoffset", ave5TotalLength)
    .transition()
    .duration(duration)
    .ease("linear-in-out")
    .attr("stroke-dashoffset", 0);

  //10日均线
  var line10 = d3.svg
    .line()
    .x(function(d) {
      return xScale(d.lYmd) + xScale.rangeBand() / 2;
    })
    .y(function(d) {
      return yScale(d.ave10);
    });
  var ave10 = svg
    .append("path")
    .attr("class", "line10")
    .attr("d", line10(dataset));
  const ave10TotalLength = ave10.node().getTotalLength();

  ave10
    .attr("stroke-dasharray", ave10TotalLength + "," + ave10TotalLength)
    .attr("stroke-dashoffset", ave10TotalLength)
    .transition()
    .duration(duration)
    .ease("linear-in-out")
    .attr("stroke-dashoffset", 0);

  //20日均线
  var line20 = d3.svg
    .line()
    .x(function(d) {
      return xScale(d.lYmd) + xScale.rangeBand() / 2;
    })
    .y(function(d) {
      return yScale(d.ave20);
    });
  var ave20 = svg
    .append("path")
    .attr("class", "line20")
    .attr("d", line20(dataset));
  const ave20TotalLength = ave20.node().getTotalLength();
  ave20
    .attr("stroke-dasharray", ave20TotalLength + "," + ave20TotalLength)
    .attr("stroke-dashoffset", ave20TotalLength)
    .transition()
    .duration(duration)
    .ease("linear-in-out")
    .attr("stroke-dashoffset", 0);

  //标注 B S 文字
  var bsTextSvg = svg.append("g").attr("class", "bs_text");
  for (var i = 0; i < dataset.length; i++) {
    //0是无效信号，1是买点，2是卖点
    // console.log(dataset[i].iBs);
    if (dataset[i].iBs == 1) {
      bsTextSvg
        .append("text")
        .attr("class", "color_buy size_15 font_bold")
        .attr({
          x: xScale(dataset[i].lYmd) - 3,
          y: yScale(dataset[i].fLow) + 20,
        })
        .text("D");
      bsTextSvg.append("line").attr({
        x1: xScale(dataset[i].lYmd) + xScale.rangeBand() / 2,
        x2: xScale(dataset[i].lYmd) + xScale.rangeBand() / 2,
        y1: yScale(dataset[i].fLow) + 7,
        y2: yScale(dataset[i].fLow) + 4,
        class: "line_buy",
      });
    } else if (dataset[i].iBs == 2) {
      bsTextSvg
        .append("text")
        .attr("class", "color_sell size_15 font_bold")
        .attr({
          x: xScale(dataset[i].lYmd) - 3,
          y: yScale(dataset[i].fHigh) - 9,
        })
        .text("K");
      bsTextSvg.append("line").attr({
        x1: xScale(dataset[i].lYmd) + xScale.rangeBand() / 2,
        x2: xScale(dataset[i].lYmd) + xScale.rangeBand() / 2,
        y1: yScale(dataset[i].fHigh) - 8,
        y2: yScale(dataset[i].fHigh) - 4,
        class: "line_sell",
      });
    }
  }

  /*
     得到所有b-s的数组
     日期，今开，最低，最高，今收,bs(0无效1买2卖)
 */
  var bsTotalArray = []; //b-s的总和
  var bsSingleArray = []; //b-s的单个
  var sbTotalArray = []; //s-b的总和
  var sbSingleArray = []; //s-b的单个
  var tempHigh = []; //tempHign存储最高点的x轴和y轴值
  var tempLow = []; //tempLow存储最高点的x轴和y轴值
  for (var i = 0; i < dataset.length; i++) {
    bsSingleArray = [];
    sbSingleArray = [];
    // console.log(dataset[i].iBs);
    if (dataset[i].iBs === undefined) {
      break;
    }
    if (dataset[i].iBs == 1) {
      bsSingleArray.push([dataset[i].lYmd, (dataset[i].fLow + dataset[i].fHigh) / 2]); //买点为最高最低的中间点，为了最大限度被看见
      tempHigh = [dataset[i].lYmd, dataset[i].fHigh];
      for (var j = i; j < dataset.length; j++) {
        if (dataset[j].fHigh > tempHigh[1]) {
          tempHigh = [dataset[j].lYmd, dataset[j].fHigh];
        }
        if (dataset[j].iBs == 2) {
          bsSingleArray.push(tempHigh); //最高点
          bsSingleArray.push([dataset[j].lYmd, (dataset[j].fLow + dataset[j].fHigh) / 2]); //卖点为最高最低的中间点，为了最大限度被看见
          bsTotalArray.push(bsSingleArray);
          break;
        }
      }
    } else if (dataset[i].iBs == 2) {
      sbSingleArray.push([dataset[i].lYmd, (dataset[i].fLow + dataset[i].fHigh) / 2]); //卖点柱子高点
      tempLow = [dataset[i].lYmd, dataset[i].fLow];
      for (var j = i; j < dataset.length; j++) {
        if (dataset[j].fLow < tempLow[1]) {
          tempLow = [dataset[j].lYmd, dataset[j].fLow];
        }
        if (dataset[j].iBs == 1) {
          sbSingleArray.push(tempLow); //最低点
          sbSingleArray.push([dataset[j].lYmd, (dataset[j].fLow + dataset[j].fHigh) / 2]); //买点柱子低点
          sbTotalArray.push(sbSingleArray);
          break;
        }
      }
    }
  }

  /*
     绘制所有b-s以及s-b三角形
 */
  // console.log(bsTotalArray);
  // console.log(sbTotalArray);
  var area = d3.svg.area();
  var triangleSvg = svg.append("g").attr("class", "bs_triangle");
  var dot1, dot2, dot3;
  var halfBandWidth = xScale.rangeBand() / 2;
  for (var i = 0; i < bsTotalArray.length; i++) {
    dot1 = [xScale(bsTotalArray[i][0][0]) + halfBandWidth, yScale(bsTotalArray[i][0][1])];
    dot2 = [xScale(bsTotalArray[i][1][0]) + halfBandWidth, yScale(bsTotalArray[i][1][1])];
    dot3 = [xScale(bsTotalArray[i][2][0]) + halfBandWidth, yScale(bsTotalArray[i][2][1])];
    triangleSvg
      .append("path")
      .attr("class", "triangle_buy")
      .attr(
        "d",
        area([
          [dot1[0], dot1[1]],
          [dot2[0], dot2[1]],
          [dot3[0], dot3[1]],
          [dot1[0], dot1[1]],
        ])
      );
  }
  for (var i = 0; i < sbTotalArray.length; i++) {
    dot1 = [xScale(sbTotalArray[i][0][0]) + halfBandWidth, yScale(sbTotalArray[i][0][1])];
    dot2 = [xScale(sbTotalArray[i][1][0]) + halfBandWidth, yScale(sbTotalArray[i][1][1])];
    dot3 = [xScale(sbTotalArray[i][2][0]) + halfBandWidth, yScale(sbTotalArray[i][2][1])];
    triangleSvg
      .append("path")
      .attr("class", "triangle_sell")
      .attr(
        "d",
        area([
          [dot1[0], dot1[1]],
          [dot2[0], dot2[1]],
          [dot3[0], dot3[1]],
          [dot1[0], dot1[1]],
        ])
      );
  }

  //四周线框
//   var rectsAround = svg.append("rect").attr({
//     x: 0.5,
//     y: 0.5,
//     width: width - 1,
//     height: height + margin.top + margin.bottom - 1.5,
//     class: "around_border",
//   });
}

//画量
export function drawVolume(options) {
  var margin = { top: 5, bottom: 2, left: 0, right: 0 };

  var width = options.width;
  var height = width * options.ratio;
  var dataset = options.data;

  var svg = d3
    .select("#" + options.id)
    .html("")
    .append("svg")
    .attr("width", width)
    .attr("height", height);

  height = height - margin.top - margin.bottom;

  //成交量文字
  var volumeTxt = svg
    .append("text")
    .attr({
      x: 44,
      y: 28,
      class: "size_24 color_gray",
      "text-anchor": "middle",
    })
    .text("成交量");

  //网格线
//   var gridLineHoriz = svg.append("line").attr({
//     x1: 0,
//     x2: width,
//     y1: height / 2 + margin.top,
//     y2: height / 2 + margin.top,
//     class: "grid_line",
//   });

  //三条竖线
//   for (var i = 0; i < 3; i++) {
//     svg.append("line").attr({
//       x1: (width / 4) * (i + 1),
//       x2: (width / 4) * (i + 1),
//       y1: 0,
//       y2: height + margin.top + margin.bottom,
//       class: "grid_line",
//     });
//   }

  var yMax = d3.max(dataset, function(d) {
    return d.lVolume;
  });

  //顺序没有差异，对应关系有很大差异，如果domain里的最大值对应range里的最小值，那么yScale(value)得到的高度是剩余高度
  var yScale = d3.scale
    .linear()
    .domain([yMax, 0])
    .range([margin.top, height + margin.top]);

  //x轴序数比例尺
  var xScale = d3.scale
    .ordinal()
    .domain(
      dataset.map(function(d) {
        return d.lYmd;
      })
    )
    .rangeBands([0, width], 0.3);

  //volumn的柱子
  var rects = svg
    .append("g")
    .attr("class", "k_bars_group")
    .selectAll("rect")
    .data(dataset)
    .enter()
    .append("rect")
    .attr({
      x: function(d, i) {
        return xScale(d.lYmd);
      },
      y: function(d, i) {
        return yScale(0);
      },
      width: function(d, i) {
        return xScale.rangeBand();
      },
      height: function(d, i) {
        return 0;
      },
      class: function(d) {
        if (d.fOpen <= d.fClose) return "kbar_positive";
        else return "kbar_negative";
      },
    });

  rects
    .transition()
    .delay(function(d, i) {
      return i * 60;
    })
    .duration(60)
    // .ease(d3.easeLinear)
    .attr("y", function(d) {
      return yScale(d.lVolume);
    })
    .attr("height", function(d) {
      return yScale(0) - yScale(d.lVolume);
    });

  //四周线框
//   var rectsAround = svg.append("rect").attr({
//     x: 0.5,
//     y: 0.5,
//     width: width - 1,
//     height: height + margin.top + margin.bottom - 1.5,
//     class: "around_border",
//   });
}
